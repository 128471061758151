import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "utils/api";
import { actionTypes } from "./const";
import {
  IGetVENInfo,
  IUpdateCPInfo,
  IUpdateCPInfoBody,
  IUpdateVEN,
  IUpdateVENBody,
} from "./types";

const VEN_URL = "ven";
const MIRRORED_METER_URL = `${VEN_URL}/meter`;
const CP_URL = `${VEN_URL}/cps`;

export const getVEN = createAsyncThunk(
  actionTypes.GET_VEN,
  async (_, thunkAPI) => {
    try {
      const response = await Api.get(`${VEN_URL}/`);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const updateVEN = createAsyncThunk(
  actionTypes.UPDATE_VEN,
  async (params: IUpdateVEN, thunkAPI) => {
    const { id, ...rest } = params;
    try {
      const body: IUpdateVENBody = {
        ...rest,
      };

      const response = await Api.put(`${VEN_URL}${id}`, body);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const getMirroredMeter = createAsyncThunk(
  actionTypes.GET_METER,
  async (params: IGetVENInfo, thunkAPI) => {
    const { id } = params;
    try {
      const response = await Api.get(`${MIRRORED_METER_URL}/${id}`);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const getChargePoint = createAsyncThunk(
  actionTypes.GET_CP,
  async (params: IGetVENInfo, thunkAPI) => {
    const { id } = params;
    try {
      const response = await Api.get(`${CP_URL}/${id}`);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const updateChargePoint = createAsyncThunk(
  actionTypes.UPDATE_CP,
  async (params: IUpdateCPInfo, thunkAPI) => {
    const { id, target_evse } = params;
    try {
      const body: IUpdateCPInfoBody = {
        target_evse,
        duration: 900,
      };
      const response = await Api.put(`${CP_URL}/${id}`, body);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);
