import { RootState } from "redux-features/store";

export const selectVEN = (state: RootState) => state.ven.ven;
export const selectMirroredMeter = (state: RootState) =>
  state.ven.mirroredMeter;
export const selectChargePoints = (state: RootState) => state.ven.chargePoints;
export const selectVENError = (state: RootState) => state.ven.errorMessage;
export const userSelectedVEN = (state: RootState) => state.ven.selectedVEN;

export const selectChargePointsLoading = (state: RootState) =>
  state.ven.getChargePoint.loading;
export const selectMirroredMeterLoading = (state: RootState) =>
  state.ven.getMirroredMeter.loading;
export const selectRefreshedTime = (state: RootState) =>
  state.ven.refreshedTime;

export const selectUpdateChargePointSuccess = (state: RootState) =>
  state.ven.updateChargePoint.successMessage;
