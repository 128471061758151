import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
`;

type TStepButton = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
};

export const StepButton: React.FC<TStepButton> = ({
  title,
  disabled,
  onClick,
}) => (
  <StyledButton
    disabled={disabled}
    type="primary"
    size={"large"}
    onClick={onClick}
  >
    {title}
  </StyledButton>
);

export default StepButton;
