import { AuthForm } from "components/form";
import { CoreLayout } from "components/layouts";
import useOnboard from "./hook/useOnboard";

const OnBoardPage: React.FC = () => {
  const { onFinish } = useOnboard();

  return (
    <CoreLayout>
      <AuthForm onFinish={onFinish} />
    </CoreLayout>
  );
};
export default OnBoardPage;
