import { ErrorWrapper } from "components/wrapper";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { selectAuthToken } from "redux-features/auth/selectors";
import { useAppSelector } from "redux-features/hooks";
import AppRoutes from "routes";
import { GlobalStyle } from "theme";
import { clearAxiosToken, updateAxiosToken } from "utils/api";
const App = () => {
  const authToken = useAppSelector(selectAuthToken);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (authToken) {
      updateAxiosToken(authToken);
      setIsReady(true);
    } else {
      clearAxiosToken();
      setIsReady(true);
    }
  }, [authToken]);
  return (
    <ErrorWrapper>
      <GlobalStyle />
      <BrowserRouter>{isReady && <AppRoutes />}</BrowserRouter>
    </ErrorWrapper>
  );
};

export default App;
