import { Flex } from "antd";
import { LogoutButton } from "components/button";
import { CoreLogo } from "components/logo";
import React from "react";
import { useTranslation } from "react-i18next";
import store from "redux-features/store";
import { RESET_STATE_ACTION_TYPE } from "redux-features/types";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  justify-content: space-between;
`;

export const CoreHeader: React.FC = () => {
  const { t } = useTranslation();

  const userLogout = () => {
    //dispatch(logOut());
    store.dispatch({ type: RESET_STATE_ACTION_TYPE });
  };
  return (
    <StyledFlex>
      <CoreLogo width={150} />
      <LogoutButton onClick={userLogout} title={t("auth.logout")} />
    </StyledFlex>
  );
};

export default CoreHeader;
