import { Flex } from "antd";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  padding: 24px 48px;
  flex-direction: column;
`;

export const MainContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledFlex vertical flex={1}>
    {children}
  </StyledFlex>
);

export default MainContainer;
