import React from "react";
import styled from "styled-components";
import { Layout } from "antd";
import backgroundImage from "assets/images/bg.png";

const StyledLayout = styled(Layout)`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100vw;
`;

export const CoreLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

export default CoreLayout;
