import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./const";
import { RESET_STATE_ACTION_TYPE } from "redux-features/types";

export interface IuserActivitySlice {
  currentStep: number;
}

const initialState: IuserActivitySlice = {
  currentStep: 0,
};

const userActivitySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
  },
});

export const { updateCurrentStep } = userActivitySlice.actions;

export default userActivitySlice.reducer;
