import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledAdditionalInfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: 1px solid lightgray;
`;

export const AdditionalInfoContainer: React.FC<PropsWithChildren> = ({
  children,
}) => <StyledAdditionalInfoContainer>{children}</StyledAdditionalInfoContainer>;

export default AdditionalInfoContainer;
