import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "utils/api";
import { actionTypes } from "./const";
import { IUpdateVTN, IUpdateVTNBody } from "./types";

const VTN_URL = "vtn";

export const getVTN = createAsyncThunk(
  actionTypes.GET_VTN,
  async (_, thunkAPI) => {
    try {
      const response = await Api.get(`${VTN_URL}/`);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const updateVTN = createAsyncThunk(
  actionTypes.UPDATE_VTN,
  async (params: IUpdateVTN, thunkAPI) => {
    const { id, ...rest } = params;
    try {
      const body: IUpdateVTNBody = {
        ...rest,
      };
      const response = await Api.put(`${VTN_URL}${id}`, body);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);
