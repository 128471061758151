import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, //from .env
});

export const clearAxiosToken = () => {
  delete axiosInstance.defaults.headers.common["Authorization"];
};

export const updateAxiosToken = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // const authToken = getAuthToken();

    // if (authToken) {
    //   config.headers.Authorization = `Bearer ${authToken}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
