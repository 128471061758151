import { ContentContainer, MainContainer } from "components/container";
import { CoreHeader } from "components/header";
import { CoreLayout } from "components/layouts";
import { CoreStepper } from "components/stepper";
import "components/text/textStyle.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux-features/hooks";
import { selectCurrentStep } from "redux-features/userActivity/selectors";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const steps = [
    { title: t(`steps.step1`) },
    { title: t(`steps.step2`) },
    { title: t(`steps.step3`) },
    // { title: t(`steps.step4`) },
  ];

  const currentUserStep = useAppSelector(selectCurrentStep);

  const renderSteps: { [key: number]: JSX.Element } = {
    0: <StepOne />,
    1: <StepTwo />,
    2: <StepThree />,
    // 3: <StepFour />,
  };

  return (
    <CoreLayout>
      <MainContainer>
        <CoreHeader />
        <CoreStepper current={currentUserStep} items={steps} />
        <ContentContainer>{renderSteps[currentUserStep]}</ContentContainer>
      </MainContainer>
    </CoreLayout>
  );
};
export default DashboardPage;
