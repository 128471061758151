import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./const";
import { RESET_STATE_ACTION_TYPE } from "redux-features/types";

export interface IchargersSlice {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  chargers: string[];
}

const initialState: IchargersSlice = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  chargers: [],
};

const chargersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateChargers: (state, action) => {
      state.chargers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
  },
});

export const { updateChargers } = chargersSlice.actions;

export default chargersSlice.reducer;
