import { Flex } from "antd";
import { StepButton } from "components/button";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { selectCurrentStep } from "redux-features/userActivity/selectors";
import { updateCurrentStep } from "redux-features/userActivity/slice";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  margin-top: 20px;
`;

type TCoreFooter = {
  hidePrev?: boolean;
  hideNext?: boolean;
  nextDisabled?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  disableHandlePrevStep?: boolean; //handle prev step manually
  disableHandleNextStep?: boolean; //handle next step manually
  prevLabel?: string;
  nextLabel?: string;
};

const CoreFooter: React.FC<TCoreFooter> = ({
  hidePrev = false,
  hideNext = false,
  nextDisabled = false,
  onPrev,
  onNext,
  disableHandlePrevStep = false,
  disableHandleNextStep = false,
  prevLabel,
  nextLabel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUserStep = useAppSelector(selectCurrentStep);

  const goPrev = async () => {
    if (onPrev) await onPrev();
    if (!disableHandlePrevStep)
      dispatch(updateCurrentStep(currentUserStep - 1));
  };
  const goNext = async () => {
    if (onNext) await onNext();
    if (!disableHandleNextStep)
      dispatch(updateCurrentStep(currentUserStep + 1));
  };

  return (
    <StyledFlex justify="space-between" align="end">
      {hidePrev ? (
        <div></div>
      ) : (
        <StepButton title={prevLabel ?? t("steps.prev")} onClick={goPrev} />
      )}
      {hideNext ? (
        <div></div>
      ) : (
        <StepButton
          disabled={nextDisabled}
          title={nextLabel ?? t("steps.next")}
          onClick={goNext}
        />
      )}
    </StyledFlex>
  );
};
export default CoreFooter;
