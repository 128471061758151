import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

type TSubmitButton = {
  title: string;
};

export const SubmitButton: React.FC<TSubmitButton> = ({ title }) => (
  <StyledButton type="primary" htmlType="submit">
    {title}
  </StyledButton>
);

export default SubmitButton;
