import { Navigate, Route, Routes } from "react-router-dom";
import { selectAuthToken } from "redux-features/auth/selectors";
import { useAppSelector } from "redux-features/hooks";
import { Paths } from "./paths";
import OnBoardPage from "pages/onboard";
import DashboardPage from "pages/dashboard";

type TProtectedRoute = React.PropsWithChildren & {
  authenticate: boolean;
};

const ProtectedRoute: React.FC<TProtectedRoute> = ({
  authenticate,
  children,
}) => {
  const isAuthenticated = useAppSelector(selectAuthToken);
  if (authenticate && !isAuthenticated) {
    // User is not authenticated, redirect to the login page
    return <Navigate to={Paths.onboard} replace />;
  } else if (!authenticate && isAuthenticated) {
    // User is authenticated, redirect to the dashboard page
    return <Navigate to={Paths.dashboard} replace />;
  }
  return <>{children}</>;
};

const unauthenticatedRoutes = {
  [Paths.onboard]: <OnBoardPage />,
};

const authenticatedRoutes = {
  [Paths.dashboard]: <DashboardPage />,
};

const AppRoutes = () => (
  <Routes>
    {Object.keys(unauthenticatedRoutes).map((routeKey) => (
      <Route
        key={routeKey}
        path={routeKey}
        Component={() => (
          <ProtectedRoute authenticate={false}>
            {unauthenticatedRoutes[routeKey]}
          </ProtectedRoute>
        )}
      />
    ))}
    {Object.keys(authenticatedRoutes).map((routeKey) => (
      <Route
        key={routeKey}
        path={routeKey}
        Component={() => (
          <ProtectedRoute authenticate={true}>
            {authenticatedRoutes[routeKey]}
          </ProtectedRoute>
        )}
      />
    ))}
    <Route path="*" element={<Navigate to={Paths.onboard} replace />} />
  </Routes>
);

export default AppRoutes;
