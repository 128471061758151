import { FieldValues } from "components/form";
import { login, signUp } from "redux-features/auth/actions";
import { useAppDispatch } from "redux-features/hooks";

const useOnboard = () => {
  const dispatch = useAppDispatch();

  const onFinish =
    (isLogin = true) =>
    async (values: FieldValues) => {
      if (isLogin) {
        dispatch(
          login({
            ...values,
            username: values.username || "",
          })
        );
      } else {
        dispatch(
          signUp({
            ...values,
            email: values.email || "",
            fullname: values.fullname || "",
          })
        );
      }
    };
  return {
    onFinish,
  };
};

export default useOnboard;
