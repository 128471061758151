import { DatePicker } from "antd";
import { PickerTimeProps } from "antd/es/time-picker";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import React from "react";

export interface TimePickerProps
  extends Omit<PickerTimeProps<Moment>, "picker"> {}

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

const MyTimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
  <MyDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

export { MyDatePicker, MyTimePicker };
