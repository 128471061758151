import { createSlice } from "@reduxjs/toolkit";
import {
  getChargePoint,
  getMirroredMeter,
  getVEN,
  updateChargePoint,
  updateVEN,
} from "./actions";
import { SLICE_NAME } from "./const";
import { TChargePoints, TMirroredMeter, TVEN } from "./types";
import {
  RESET_ERROR,
  RESET_STATE_ACTION_TYPE,
  TAsyncActionType,
} from "redux-features/types";
import { initialAsyncActionState } from "redux-features/const";

export interface IVENSlice {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  ven: TVEN[];
  getMirroredMeter: TAsyncActionType;
  mirroredMeter: TMirroredMeter[];
  getChargePoint: TAsyncActionType;
  updateChargePoint: TAsyncActionType;
  chargePoints: TChargePoints[];
  selectedVEN: TVEN | null;
  refreshedTime: string;
}

const initialState: IVENSlice = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  ven: [],
  getMirroredMeter: initialAsyncActionState,
  mirroredMeter: [],
  getChargePoint: initialAsyncActionState,
  updateChargePoint: initialAsyncActionState,
  chargePoints: [],
  selectedVEN: null,
  refreshedTime: "",
};

const venSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSelectedVEN: (state, action) => {
      state.selectedVEN = action.payload;
    },
    updateRefreshedTime: (state, action) => {
      state.refreshedTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVEN.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVEN.fulfilled, (state, action) => {
        state.loading = false;
        state.ven = action.payload.data;
      })
      .addCase(getVEN.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      });
    builder
      .addCase(updateVEN.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVEN.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateVEN.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = (action.payload as string) || "";
      });
    builder
      .addCase(getMirroredMeter.pending, (state) => {
        state.getMirroredMeter.loading = true;
      })
      .addCase(getMirroredMeter.fulfilled, (state, action) => {
        state.getMirroredMeter.loading = false;
        state.mirroredMeter = action.payload.data;
      })
      .addCase(getMirroredMeter.rejected, (state, action) => {
        state.getMirroredMeter.loading = false;
        state.errorMessage = (action.payload as string) || "";
      });
    builder
      .addCase(getChargePoint.pending, (state) => {
        state.getChargePoint.loading = true;
      })
      .addCase(getChargePoint.fulfilled, (state, action) => {
        state.getChargePoint.loading = false;
        state.chargePoints = action.payload.data[0]?.ChargePoints;
      })
      .addCase(getChargePoint.rejected, (state, action) => {
        state.getChargePoint.loading = false;
        state.errorMessage = (action.payload as string) || "";
      });
    builder
      .addCase(updateChargePoint.pending, (state) => {
        state.updateChargePoint.loading = true;
      })
      .addCase(updateChargePoint.fulfilled, (state, action) => {
        state.updateChargePoint.loading = false;
        state.updateChargePoint.success = true;
        state.updateChargePoint.successMessage = action.payload.message;
      })
      .addCase(updateChargePoint.rejected, (state) => {
        console.log(state.updateChargePoint);
        state.updateChargePoint.loading = false;
        state.updateChargePoint.success = false;
      });
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
    builder.addCase(RESET_ERROR, (state) => {
      state.hasErrors = false;
      state.errorMessage = "";
      state.getMirroredMeter = initialAsyncActionState;
      state.getChargePoint = initialAsyncActionState;
      state.updateChargePoint = initialAsyncActionState;
    });
  },
});

export const { updateSelectedVEN, updateRefreshedTime } = venSlice.actions;

export default venSlice.reducer;
