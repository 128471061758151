import { Form, Input, Tabs } from "antd";
import { SubmitButton } from "components/button";
import { CoreLogo } from "components/logo";
import { RuleType } from "rc-field-form/lib/interface";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const FormContainer = styled.div`
  padding: 24px;
  min-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;

const AuthTabs = styled(Tabs)`
  width: 100%;
`;

type FieldType = {
  label: string;
  name: string;
  component: React.ReactNode;
  rule?: {
    type: RuleType;
    message: string;
  }[];
}[];

export type FieldValues = {
  username?: string;
  email?: string;
  password: string;
  fullname?: string;
};

interface IReusableForm {
  fields: FieldType;
  onFinish: (values: FieldValues) => void;
}

const ReusableForm: React.FC<IReusableForm> = ({ fields, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" onFinish={onFinish}>
      {fields.map((field) => (
        <Form.Item
          key={field.name}
          label={field.label}
          name={field.name}
          rules={[
            {
              required: true,
              message: `${field.label} is required`,
            },
            ...(field.rule ?? []),
          ]}
        >
          {field.component}
        </Form.Item>
      ))}
      <Form.Item>
        <SubmitButton title={t("auth.submit")} />
      </Form.Item>
    </Form>
  );
};

interface IAuthForm {
  onFinish: (isLogin: boolean) => (values: FieldValues) => void;
}

export const AuthForm = ({ onFinish }: IAuthForm) => {
  const { t } = useTranslation();
  const loginFields: FieldType = [
    {
      label: t("auth.email.title"),
      name: "username",
      component: <Input placeholder={t("auth.email.title")} />,
      rule: [
        {
          type: "email",
          message: "Please enter a valid email",
        },
      ],
    },
    {
      label: t("auth.password.title"),
      name: "password",
      component: <Input.Password placeholder={t("auth.password.title")} />,
    },
  ];

  const signUpFields: FieldType = [
    {
      label: t("auth.fullName.title"),
      name: "fullname",
      component: <Input placeholder={t("auth.fullName.title")} />,
    },
    {
      label: t("auth.email.title"),
      name: "email",
      component: <Input placeholder={t("auth.email.title")} />,
      rule: [
        {
          type: "email",
          message: "Please enter a valid email",
        },
      ],
    },
    {
      label: t("auth.password.title"),
      name: "password",
      component: <Input.Password placeholder={t("auth.password.title")} />,
    },
  ];

  const items = [
    {
      label: t(`login.title`),
      children: loginFields,
    },
    {
      label: t(`signUp.title`),
      children: signUpFields,
    },
  ];

  return (
    <StyledContainer>
      <FormContainer>
        <CoreLogo />
        <AuthTabs
          centered
          defaultActiveKey="1"
          items={items.map((item, index) => ({
            ...item,
            key: `${index + 1}`,
            children: (
              <ReusableForm
                fields={item.children}
                onFinish={onFinish(index === 0)}
              />
            ),
          }))}
        />
      </FormContainer>
    </StyledContainer>
  );
};

export default AuthForm;
