import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./const";
import { login, signUp } from "./actions";
import { RESET_ERROR, RESET_STATE_ACTION_TYPE } from "redux-features/types";

export interface IAuthSlice {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  token: null | string;
}

const initialState: IAuthSlice = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  token: null,
};

const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    logOut: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.access_token) {
          state.token = action.payload.access_token;
        } else {
          state.errorMessage = action.payload;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      });
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      });
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
    builder.addCase(RESET_ERROR, (state) => {
      state.hasErrors = false;
      state.errorMessage = "";
    });
  },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;
