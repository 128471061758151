import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SyncOutlined } from "@ant-design/icons";

const StyledButton = styled(Button)`
  padding-left: 25px;
  padding-right: 30px;
  width: fit-content;
  background-color: #30d7ba;
  margin-left: 10px;
`;

type TRefreshButton = {
  disabled?: boolean;
  spin?: boolean;
  onClick: () => void;
};

export const RefreshButton: React.FC<TRefreshButton> = ({
  disabled = false,
  spin,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      disabled={disabled}
      type="primary"
      size={"large"}
      onClick={onClick}
    >
      <SyncOutlined spin={spin} />
      {t("steps.refresh")}
    </StyledButton>
  );
};

export default RefreshButton;
