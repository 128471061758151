import logo from "assets/images/logo.png";
import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: ${(props) => props.width || "100px"};
`;

type TCoreLogo = {
  width?: number;
};

export const CoreLogo: React.FC<TCoreLogo> = ({ width = 100 }) => {
  return <StyledImage src={logo} alt="Logo" width={width} />;
};

export default CoreLogo;
