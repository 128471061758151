import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import theme from "theme";

const StyledButton = styled(Button)`
  background: ${theme.colors.button.primaryColor};
`;

type TLogoutButton = {
  title: string;
  onClick: () => void;
};

export const LogoutButton: React.FC<TLogoutButton> = ({ title, onClick }) => (
  <StyledButton type="primary" size="middle" onClick={onClick}>
    {title}
  </StyledButton>
);

export default LogoutButton;
