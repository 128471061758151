import { Flex } from "antd";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledContentContainer = styled(Flex)`
  padding: 24px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: #fffff0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
`;

export const ContentContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledContentContainer flex={1} vertical>
    {children}
  </StyledContentContainer>
);

export default ContentContainer;
