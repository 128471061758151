import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledCardContainer = styled.div`
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
`;

export const CardContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledCardContainer>{children}</StyledCardContainer>
);

export default CardContainer;
