import {
  Button,
  Flex,
  Form,
  FormInstance,
  GetProps,
  Input,
  Radio,
  Select,
} from "antd";
import { MyDatePicker, MyTimePicker } from "components/dateTimePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { TVEN } from "redux-features/ven/types";
import { selectVTNForm } from "redux-features/vtn/selectors";
import { updateVTNForm } from "redux-features/vtn/slice";
import { TSignalName } from "redux-features/vtn/types";
import styled from "styled-components";

type RangePickerProps = GetProps<typeof MyDatePicker.RangePicker>;

const FormContainer = styled.div`
  padding: 24px;
  width: 60%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;

const DateTimeContainer = styled(Flex)`
  display: flex;
`;

const StyledFormItem = styled(Form.Item)`
  margin-top: 10px;
`;

const DateTimeFormItem = styled(StyledFormItem)`
  flex: 1;
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledRadioButton = styled(Radio.Button)`
  width: 50%;
  text-align: center;
`;

const StyledDatePicker = styled(MyDatePicker)`
  width: 50%;
`;

const StyledTimePicker = styled(MyTimePicker)`
  width: 50%;
`;

interface IUpdateVTNForm {
  onFinish: (values: {
    startDate: moment.Moment;
    startTime: moment.Moment;
    duration: string | number;
    signal: TSignalName;
    load_reduction: string | number;
    targets: string;
  }) => void;
  selectedVEN: TVEN | null;
  form: FormInstance<any>;
}

const UpdateVTNForm: React.FC<IUpdateVTNForm> = ({
  onFinish,
  selectedVEN,
  form,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userFilledVTNForm = useAppSelector(selectVTNForm);

  const [suffix, setSuffix] = useState<string>("Watts"); // Default suffix
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const simpleOptions = [
    {
      label: "10%",
      value: 0,
    },
    {
      label: "20%",
      value: 1,
    },
    {
      label: "50%",
      value: 2,
    },
    {
      label: "80%",
      value: 3,
    },
    {
      label: "100%",
      value: 4,
    },
  ];

  const loadReductionSuffix = {
    LOAD_CONTROL: "Watts",
    SIMPLE: "%",
  };

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const errors = form.getFieldsError();
    const hasErrors = errors.some(({ errors }) => errors.length);
    setSubmitDisabled(
      hasErrors || Object.keys(allValues).some((key) => !allValues[key])
    );
  };
  // Handle form value changes
  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    const { startDate, startTime } = allValues;
    dispatch(
      updateVTNForm({
        ...allValues,
        startDate: startDate ? moment(startDate).toISOString() : undefined,
        startTime: startTime ? moment(startTime).toISOString() : undefined,
      })
    );

    if ("signal" in changedValues) {
      // Update the suffix based on the signal value
      setSuffix(loadReductionSuffix[changedValues.signal as TSignalName]);
      form.setFieldValue("load_reduction", undefined);
    }
  };

  useEffect(() => {
    // Initially check if form is valid to set button state
    const checkFormErrors = () => {
      const errors = form.getFieldsError();

      const hasErrors = errors.some(({ errors }) => errors.length);
      setSubmitDisabled(hasErrors);
    };

    checkFormErrors(); // Check on mount for default values if any
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  return (
    <FormContainer>
      <Form
        layout="vertical"
        variant="outlined"
        onFinish={onFinish}
        initialValues={{
          ...userFilledVTNForm,
          startDate: userFilledVTNForm?.startDate
            ? moment(userFilledVTNForm?.startDate)
            : userFilledVTNForm?.startDate,
          startTime: userFilledVTNForm?.startTime
            ? moment(userFilledVTNForm?.startTime)
            : userFilledVTNForm?.startTime,
        }}
        onFieldsChange={handleFieldChange}
        onValuesChange={handleFormValuesChange}
        form={form}
      >
        <Form.Item label={t("dashboard.step3.form.venName")}>
          <Input disabled value={selectedVEN?.ven_name} />
        </Form.Item>
        <DateTimeContainer flex={1}>
          <DateTimeFormItem
            label={t("dashboard.step3.form.startDate")}
            name="startDate"
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  label: t("dashboard.step3.form.startDate"),
                }),
              },
            ]}
          >
            <StyledDatePicker disabledDate={disabledDate} />
          </DateTimeFormItem>
          <DateTimeFormItem
            label={t("dashboard.step3.form.startTime")}
            name="startTime"
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  label: t("dashboard.step3.form.startTime"),
                }),
              },
            ]}
          >
            <StyledTimePicker />
          </DateTimeFormItem>
        </DateTimeContainer>
        <StyledFormItem
          name="duration"
          label={t("dashboard.step3.form.duration")}
          rules={[
            {
              required: true,
              message: t("validation.required", {
                label: t("dashboard.step3.form.duration"),
              }),
            },
            () => ({
              validator(_, value) {
                if (!value || /^\d+$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("validation.numeric")));
              },
            }),
          ]}
        >
          <Input
            placeholder={t("dashboard.step3.form.duration")}
            suffix="minutes"
          />
        </StyledFormItem>
        <StyledFormItem
          name="signal"
          label={t("dashboard.step3.form.signal")}
          rules={[
            {
              required: true,
              message: t("validation.required", {
                label: t("dashboard.step3.form.signal"),
              }),
            },
          ]}
        >
          <StyledRadioGroup>
            <StyledRadioButton value={`LOAD_CONTROL`}>
              {t("dashboard.step3.form.loadControl")}
            </StyledRadioButton>
            <StyledRadioButton value={`SIMPLE`}>
              {t("dashboard.step3.form.simple")}
            </StyledRadioButton>
          </StyledRadioGroup>
        </StyledFormItem>
        <StyledFormItem
          name="load_reduction"
          label={t("dashboard.step3.form.loadReduction")}
          rules={[
            {
              required: true,
              message: t("validation.required", {
                label: t("dashboard.step3.form.loadReduction"),
              }),
            },
            () => ({
              validator(_, value) {
                if (!value || /^\d+$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("validation.numeric")));
              },
            }),
          ]}
        >
          {suffix === "%" ? (
            <Select placeholder={t("dashboard.step3.form.loadReduction")}>
              {simpleOptions.map((option) => (
                <Select.Option key={option.label} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              placeholder={t("dashboard.step3.form.loadReduction")}
              suffix={suffix}
            />
          )}
        </StyledFormItem>
        <StyledFormItem
          name={"targets"}
          label={t("dashboard.step3.form.targets")}
          rules={[
            {
              required: true,
              message: t("validation.required", {
                label: t("dashboard.step3.form.targets"),
              }),
            },
          ]}
        >
          <Input placeholder={t("dashboard.step3.form.targetPlaceholder")} />
        </StyledFormItem>
        <Flex justify="flex-end">
          <StyledFormItem>
            <Button type="primary" htmlType="submit" disabled={submitDisabled}>
              {`Submit Event`}
            </Button>
          </StyledFormItem>
        </Flex>
      </Form>
    </FormContainer>
  );
};
export default UpdateVTNForm;
