import { notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectAuthError } from "redux-features/auth/selectors";

import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { RESET_ERROR } from "redux-features/types";
import { selectVENError } from "redux-features/ven/selectors";
import { selectVTNError, updateVTNSuccess } from "redux-features/vtn/selectors";

export const ErrorWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authErrorMessage = useAppSelector(selectAuthError);
  const vtnErrorMessage = useAppSelector(selectVTNError);
  const venErrorMessage = useAppSelector(selectVENError);

  useEffect(() => {
    let errorMessage = authErrorMessage || vtnErrorMessage || venErrorMessage;
    if (errorMessage) {
      notification.error({
        message: t("error"),
        placement: "bottom",
        description: errorMessage,
        onClose: () => {
          dispatch({ type: RESET_ERROR });
        },
      });
    }
    // eslint-disable-next-line
  }, [authErrorMessage, vtnErrorMessage, venErrorMessage]);

  const updateVTNSuccessful = useAppSelector(updateVTNSuccess);

  useEffect(() => {
    let successMessage = updateVTNSuccessful;
    if (successMessage) {
      notification.success({
        message: t("success"),
        placement: "bottom",
        description: successMessage,
        onClose: () => {
          dispatch({ type: RESET_ERROR });
        },
      });
    }
    // eslint-disable-next-line
  }, [updateVTNSuccessful]);

  return <>{children}</>;
};

export default ErrorWrapper;
