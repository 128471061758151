import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "utils/api";
import { actionTypes } from "./const";
import { ILoginParams, ISignupParams } from "./types";

const AUTH_URL = "admin";
const LOGIN_URL = `${AUTH_URL}/login`;
const SIGNUP_URL = AUTH_URL;

export const login = createAsyncThunk(
  actionTypes.LOGIN,
  async (params: ILoginParams, thunkAPI) => {
    const { username, password } = params;
    try {
      const body: ILoginParams = {
        username,
        password,
      };

      const response = await Api.post(LOGIN_URL, body);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue((err as Error).message);
    }
  }
);

export const signUp = createAsyncThunk(
  actionTypes.SIGN_UP,
  async (params: ISignupParams, thunkAPI) => {
    const { fullname, email, password } = params;
    try {
      const body: ISignupParams = {
        fullname,
        email,
        password,
      };

      const response = await Api.post(SIGNUP_URL, body);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue((err as Error).message);
    }
  }
);
