export const SLICE_NAME = "ven";

const GET_VEN = `${SLICE_NAME}/GET_VEN`;
const UPDATE_VEN = `${SLICE_NAME}/UPDATE_VEN`;
const GET_METER = `${SLICE_NAME}/GET_METER`;
const GET_CP = `${SLICE_NAME}/GET_CP`;
const UPDATE_CP = `${SLICE_NAME}/UPDATE_CP`;

export const actionTypes = {
  GET_VEN,
  UPDATE_VEN,
  GET_METER,
  GET_CP,
  UPDATE_CP,
};
