import { RootState } from "redux-features/store";

export const selectVTN = (state: RootState) => state.vtn.vtn;
export const selectVTNError = (state: RootState) => state.vtn.errorMessage;
export const userSelectedVTN = (state: RootState) => state.vtn.selectedVTN;

export const updateVTNSuccess = (state: RootState) =>
  state.vtn.updateVTN.successMessage;

export const selectVTNForm = (state: RootState) => state.vtn.vtnForm;
