import { CloseOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";
import React from "react";

type TSerialNumberSection = {
  value: string;
  onRemove: () => void;
};

export const SerialNumberSection: React.FC<TSerialNumberSection> = ({
  value,
  onRemove,
}) => (
  <Flex dir="row" style={{ marginTop: 5 }}>
    <Typography.Text>{`• ${value}`}</Typography.Text>
    <CloseOutlined
      onClick={onRemove}
      style={{ marginLeft: 10, cursor: "pointer", color: "red" }}
    />
  </Flex>
);

export default SerialNumberSection;
