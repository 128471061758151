import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import vtnReducer from "./vtn/slice";
import venReducer from "./ven/slice";
import userActivityReducer from "./userActivity/slice";
import chargersReducer from "./chargers/slice";

const rootReducer = combineReducers({
  auth: authReducer,
  vtn: vtnReducer,
  ven: venReducer,
  userActivity: userActivityReducer,
  chargers: chargersReducer,
});

export default rootReducer;
