import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  //margin-left: 20px;
`;

type TPrimaryButton = {
  title: string;
  htmlType?: "button" | "submit" | "reset";
  onClick: () => void;
};

export const PrimaryButton: React.FC<TPrimaryButton> = ({
  title,
  htmlType,
  onClick,
}) => (
  <StyledButton type="primary" htmlType={htmlType} onClick={onClick}>
    {title}
  </StyledButton>
);

export default PrimaryButton;
