import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./const";
import { getVTN, updateVTN } from "./actions";
import { TVTN, TVTNForm } from "./types";
import {
  RESET_ERROR,
  RESET_STATE_ACTION_TYPE,
  TAsyncActionType,
} from "redux-features/types";
import { initialAsyncActionState } from "redux-features/const";

export interface IVTNSlice {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  vtn: TVTN[];
  selectedVTN: TVTN | null;
  updateVTN: TAsyncActionType;
  vtnForm?: TVTNForm;
}

const initialState: IVTNSlice = {
  loading: false,
  hasErrors: false,
  errorMessage: "",
  vtn: [],
  selectedVTN: null,
  updateVTN: initialAsyncActionState,
  vtnForm: undefined,
};

const vtnSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSelectedVTN: (state, action) => {
      state.selectedVTN = action.payload;
    },
    updateVTNForm: (state, action) => {
      state.vtnForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVTN.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVTN.fulfilled, (state, action) => {
        state.loading = false;
        state.vtn = action.payload.data;
      })
      .addCase(getVTN.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      });
    builder
      .addCase(updateVTN.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVTN.fulfilled, (state, action) => {
        state.loading = false;
        state.updateVTN.success = true;
        state.updateVTN.successMessage = action.payload.message;
      })
      .addCase(updateVTN.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
        state.updateVTN.success = false;
      });
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
    builder.addCase(RESET_ERROR, (state) => {
      state.hasErrors = false;
      state.errorMessage = "";
      state.updateVTN = initialAsyncActionState;
    });
  },
});

export const { updateSelectedVTN, updateVTNForm } = vtnSlice.actions;

export default vtnSlice.reducer;
