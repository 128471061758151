import { Flex, Input } from "antd";
import { PrimaryButton } from "components/button";
import React from "react";
import styled from "styled-components";

const StyledButtonArea = styled.div`
  margin-left: 20px;
`;

type TInputSection = {
  value: string;
  onClick: () => void;
  placeholder?: string;
  buttonTitle: string;
  onChange: (val: string) => void;
};

export const InputSection: React.FC<TInputSection> = ({
  value,
  placeholder,
  buttonTitle,
  onClick,
  onChange,
}) => (
  <Flex dir="row" align="center">
    <Input
      value={value}
      onPressEnter={onClick}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
    <StyledButtonArea>
      <PrimaryButton title={buttonTitle} onClick={onClick} />
    </StyledButtonArea>
  </Flex>
);

export default InputSection;
