import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  html, #root, body {
    height: 100%;
    width: 100%;
    background: #30d7ba;
  }
`;

const theme = {
  colors: {
    button: {
      primaryColor: "#12B5CB",
    },
  },
};

export default theme;
