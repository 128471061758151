import { Flex, Space, Table } from "antd";
import { RefreshButton } from "components/button";
import { CardContainer } from "components/container";
import CoreFooter from "components/footer/CoreFooter";
import { Title } from "components/text";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUserSelectedChargers } from "redux-features/chargers/selectors";
import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { getMirroredMeter } from "redux-features/ven/actions";
import {
  selectChargePointsLoading,
  selectMirroredMeter,
  selectMirroredMeterLoading,
  selectRefreshedTime,
  selectVEN,
} from "redux-features/ven/selectors";
import { updateRefreshedTime } from "redux-features/ven/slice";
import { TMirroredMeter } from "redux-features/ven/types";

export const mirroredMetersKey: {
  label: string;
  value: keyof TMirroredMeter;
  unit?: string;
}[] = [
  {
    label: "Export Current",
    value: "ExportCurrent",
    unit: "A",
  },
  {
    label: "Export Power Active",
    value: "ExportPowerActive",
    unit: "W",
  },
  {
    label: "Export Power Reactive",
    value: "ExportPowerReactive",
    unit: "W",
  },
  {
    label: "Frequency",
    value: "Frequency",
    unit: "Hz",
  },
  {
    label: "Import Current",
    value: "ImportCurrent",
    unit: "A",
  },
  {
    label: "Import Power Active",
    value: "ImportPowerActive",
    unit: "W",
  },
  {
    label: "Import Power Reactive",
    value: "ImportPowerReactive",
    unit: "W",
  },
  {
    label: "Offered Current",
    value: "OfferedCurrent",
    unit: "A",
  },
  {
    label: "Offered Power",
    value: "OfferedPower",
    unit: "W",
  },
  {
    label: "Temperature",
    value: "Temperature",
  },
  {
    label: "Voltage",
    value: "Voltage",
    unit: "V",
  },
  {
    label: "pf",
    value: "pf",
  },
];

type MirroredMeterDataType = {
  key: string;
  name: string;
  value: string;
};

const StepTwo: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedVen = useAppSelector(selectVEN);

  const chargePoints = useAppSelector(selectUserSelectedChargers);
  const mirroredMeters = useAppSelector(selectMirroredMeter);

  const chargePointsLoading = useAppSelector(selectChargePointsLoading);
  const mirroredMeterLoading = useAppSelector(selectMirroredMeterLoading);

  const refreshedTime = useAppSelector(selectRefreshedTime);
  const venId = selectedVen[0].id;

  const [mirroredMeterData, setMirroredMeterData] = useState<
    MirroredMeterDataType[]
  >([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "50%",
    },
  ];

  const fetchMirroredMeter = async () =>
    await dispatch(getMirroredMeter({ id: venId as string }));

  const refreshInfos = async () => {
    await fetchMirroredMeter();
    dispatch(updateRefreshedTime(moment().format("MM-DD-YYYY HH:mm:ss")));
  };

  const resetRefreshedTime = () => dispatch(updateRefreshedTime(""));

  useEffect(() => {
    if (venId) {
      refreshInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venId]);

  useEffect(() => {
    if (mirroredMeters) {
      let updatedMirroredMeters: MirroredMeterDataType[] = [];
      mirroredMeters?.forEach((meter) => {
        mirroredMetersKey.forEach((key, index) => {
          updatedMirroredMeters.push({
            key: `${index + 1}`,
            name: t(`dashboard.step4.mirroredMeterKeys.${key.value}`),
            value: `${meter[key.value]?.toFixed(2)} ${
              key.unit ? key.unit : ""
            }`,
          });
        });
      });
      setMirroredMeterData(updatedMirroredMeters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mirroredMeters]);

  return (
    <>
      <Flex align="center">
        <Title>{t("dashboard.step2.title")}</Title>
        <Space>
          <RefreshButton
            spin={chargePointsLoading || mirroredMeterLoading}
            onClick={refreshInfos}
          />
          {refreshedTime && (
            <Title level={5}>
              {t("dashboard.step4.refreshedTime", { time: refreshedTime })}
            </Title>
          )}
        </Space>
      </Flex>
      <Flex flex={1} vertical>
        <CardContainer>
          <Title level={3}>{t("dashboard.step4.venID", { id: venId })}</Title>
        </CardContainer>
        <CardContainer>
          <Title level={3}>{t("dashboard.step4.cp")}</Title>
          {chargePoints?.map((cp) => (
            <Title key={cp} level={5}>
              {`• ${cp}`}
            </Title>
          ))}
        </CardContainer>
        <CardContainer>
          <Title level={3}>{t("dashboard.step4.mirroredMeter")}</Title>
          <Flex wrap={"wrap"} style={{ marginTop: 10 }}>
            <Table
              pagination={false}
              dataSource={mirroredMeterData}
              columns={columns}
              style={{ width: "100%" }}
            />
          </Flex>
        </CardContainer>
      </Flex>
      <CoreFooter onPrev={resetRefreshedTime} />
    </>
  );
};
export default StepTwo;
