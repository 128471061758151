import { Flex, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const ValueText = styled(Typography.Text)`
  //margin-left: 5px;
`;

type TInfoSection = {
  label: string;
  value: string | number;
  LabelComponent: React.ReactElement;
  itemsPerRow?: number;
};

export const InfoSection: React.FC<TInfoSection> = ({
  label,
  value,
  LabelComponent,
  itemsPerRow = 3,
}) => (
  <Flex vertical style={{ width: `${100 / itemsPerRow}%` }}>
    {React.cloneElement(LabelComponent, {}, <b>{`${label}:`}</b>)}
    <ValueText>{value}</ValueText>
  </Flex>
);

export default InfoSection;
