import { Typography } from "antd";
import { TitleProps } from "antd/es/typography/Title";
import React from "react";
import styled from "styled-components";

const StyledTitle = styled(Typography.Title)`
  margin: 0;
  margin-bottom: 0;
`;

export const Title: React.FC<TitleProps> = (props) => {
  return <StyledTitle {...props} />;
};

export default Title;
