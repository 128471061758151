import { Form } from "antd";
import CoreFooter from "components/footer/CoreFooter";
import UpdateVTNForm from "components/form/UpdateVTNForm";
import { Title } from "components/text";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { selectCurrentStep } from "redux-features/userActivity/selectors";
import { updateCurrentStep } from "redux-features/userActivity/slice";
import { selectVEN } from "redux-features/ven/selectors";
import { updateVTN } from "redux-features/vtn/actions";
import { selectVTN } from "redux-features/vtn/selectors";
import { updateVTNForm } from "redux-features/vtn/slice";
import { TSignalName } from "redux-features/vtn/types";

const StepThree: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedVTN = useAppSelector(selectVTN);
  const selectedVEN = useAppSelector(selectVEN);
  const currentUserStep = useAppSelector(selectCurrentStep);

  const [form] = Form.useForm();

  const [nextDisabled, setNextDisabled] = useState(true);

  const onSubmit = async (values: {
    startDate: moment.Moment;
    startTime: moment.Moment;
    duration: string | number;
    signal: TSignalName;
    load_reduction: string | number;
    targets: string;
  }) => {
    const updatedDate = moment(values.startDate);
    const updatedTime = moment(values.startTime);
    const combinedDateTime = updatedDate.clone().set({
      hour: updatedTime.get("hour"),
      minute: updatedTime.get("minute"),
      second: updatedTime.get("second"),
    });
    try {
      await dispatch(
        updateVTN({
          id: selectedVTN[0]?.id as string,
          enable: true,
          signal_name: values.signal,
          intervals: [
            {
              dtstart: moment
                .utc(combinedDateTime)
                .format("DD-MM-YYYY HH:mm:ss"),
              duration: JSON.parse(`${values.duration}`),
              signal_payload: JSON.parse(`${values.load_reduction}`),
            },
          ],
          ven_id: selectedVEN[0]?.ven_id as string,
          target_evse: values.targets
            .split(",")
            .map((target) => target.trim())
            .filter((val) => !!val),
        })
      ).unwrap();
      await dispatch(updateVTNForm(undefined));
      form.resetFields();
      setNextDisabled(false);
    } catch (e) {
      //Don't proceed to next step if there is an error
      console.log(e);
    }
  };

  const goToChargerMonitor = () => {
    dispatch(updateCurrentStep(currentUserStep - 1));
  };

  return (
    <>
      <Title>{t("dashboard.step3.title")}</Title>
      <UpdateVTNForm
        onFinish={onSubmit}
        selectedVEN={selectedVEN[0]}
        form={form}
      />
      <CoreFooter
        nextDisabled={nextDisabled}
        disableHandleNextStep
        nextLabel={t("dashboard.step2.title")}
        onNext={goToChargerMonitor}
      />
    </>
  );
};
export default StepThree;
