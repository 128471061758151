import { Space } from "antd";
import CoreFooter from "components/footer/CoreFooter";
import { InputSection, SerialNumberSection } from "components/section";
import { Title } from "components/text";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUserSelectedChargers } from "redux-features/chargers/selectors";
import { updateChargers } from "redux-features/chargers/slice";
import { useAppDispatch, useAppSelector } from "redux-features/hooks";
import { getVEN, updateChargePoint } from "redux-features/ven/actions";
import { selectVEN } from "redux-features/ven/selectors";
import { getVTN } from "redux-features/vtn/actions";
import styled from "styled-components";

const FormContainer = styled.div`
  padding: 24px;
  width: 100%;
  flex: 1;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;
const StepOne: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentChargers = useAppSelector(selectUserSelectedChargers);

  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const selectedVEN = useAppSelector(selectVEN);

  const goNext = async () => {
    try {
      await dispatch(
        updateChargePoint({
          id: selectedVEN[0].id,
          target_evse: serialNumbers,
          duration: 120,
        })
      ).unwrap();
      dispatch(updateChargers(serialNumbers));
    } catch (e) {
      console.log(e);
    }
  };

  const [serialNumberInput, setSerialNumberInput] = useState("");

  const addSerialNumber = () => {
    const userEnteredSerialNumbers = serialNumberInput
      .split(",")
      .map((target) => target.trim())
      .filter((val) => !!val);
    setSerialNumbers(
      Array.from(new Set([...serialNumbers, ...userEnteredSerialNumbers]))
    );
    setSerialNumberInput("");
  };

  const removeSerialNumber = (index: number) => () => {
    let updatedSerialNumbers = [...serialNumbers];
    updatedSerialNumbers.splice(index, 1);
    setSerialNumbers(updatedSerialNumbers);
  };

  useEffect(() => {
    if (currentChargers) {
      setSerialNumbers(currentChargers);
    }
  }, [currentChargers]);

  useEffect(() => {
    //Call ven to get ven id and vtn id
    dispatch(getVEN());
    dispatch(getVTN());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Title>{t("dashboard.step1.title")}</Title>
      <FormContainer>
        <InputSection
          value={serialNumberInput}
          placeholder={t("dashboard.step1.chargerPlaceholder")}
          onClick={addSerialNumber}
          buttonTitle={t("steps.add")}
          onChange={setSerialNumberInput}
        />
        <Space>
          <Title style={{ marginTop: 10 }} level={5}>
            {t("dashboard.step1.selectedChargers")}
          </Title>
        </Space>
        {serialNumbers.map((serialNumber, index) => (
          <SerialNumberSection
            key={`serial-number-${index}`}
            value={serialNumber}
            onRemove={removeSerialNumber(index)}
          />
        ))}
      </FormContainer>
      <CoreFooter
        nextDisabled={!serialNumbers.length}
        onNext={goNext}
        hidePrev
      />
    </>
  );
};
export default StepOne;
