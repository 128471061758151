import { Steps } from "antd";
import React from "react";
import styled from "styled-components";
import "./stepperStyle.css";

const StyledSteps = styled(Steps)`
  width: 90%;
  align-self: center;
  margin-top: 20px;
`;

type TCoreStepper = {
  current: number;
  items: { title: string }[];
};

export const CoreStepper: React.FC<TCoreStepper> = ({ current, items }) => {
  return <StyledSteps size="small" current={current} items={items} />;
};

export default CoreStepper;
